.dl-expanded\:border-ring-2:where([aria-expanded='true']),
.dl-current\:border-ring-2:where([aria-current='true']) {
  box-shadow: inset 0 0 0 2px var(--v-color-foreground-accent-blue);
}

.dl-current\:text-accent-blue:where([aria-current='true']) {
  color: var(--v-color-foreground-accent-blue);
}

.dl-hover\:border-accent-blue:hover:where(
    [aria-expanded='false'],
    [aria-current='false']
  ) {
  box-shadow: inset 0 0 0 1px var(--v-color-foreground-accent-blue);
}

.dl-h-56 {
  height: 3.5rem;
}

.dl-min-h-full-topbar {
  min-height: calc(100dvh - var(--sitenav-topbar-height));
}

.dl-order-1 {
  order: 1;
}
